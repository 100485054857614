import React, {useState} from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Helmet } from 'react-helmet';
import CreateCustomer from '../FetchApi/CreateCustomer';

export default function Contact() {
  const { createLead } = CreateCustomer(); 
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [message, setMessage] = useState();
  const [showForm, setShowForm] = useState(true);
  const handleSubmit = (e) => {
    // Send email to the server here
    e.preventDefault();
    createLead(name, email, message, phone);
    console.log(`Email sent to ${email} with subject ${message}`);
    setShowForm(false);
  }
  return (
    <>
      <Helmet>
        <title>Contact Us | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="Contact Us | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        </Helmet>
   <LoadScripts />
   
  <div>
  <section className="main-header" style={{backgroundImage: 'url(assets/images/breadcrumb.jpg)'}}>
    <header>
      <div className="container text-center">
        <h2 className="h2 title">Contact</h2>
        <ol className="breadcrumb breadcrumb-inverted">
          <li><a href="index.html"><span className="icon icon-home" /></a></li>
          <li><a className="active" href="contact.html">Contact</a></li>
        </ol>
      </div>
    </header>
  </section>
  <br /><br /><br />
  {/* ========================  Contact ======================== */}
  <section className="contact">
    {/* === Goolge map === */}
    {/* <div id="map" /> */}
 
    <div className="container-fluid">
  <div className="row">
    <div className="col-12">
      <div className="contact-block">
        <div className="contact-info">
          <div className="row">
            <div className="col-12">
            {/* https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d108111.23327453007!2d74.11043783629779!3d32.137449809564124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x391f2a4226d83811%3A0x3c090f3f28a30918!2sG.T.%20Road%2C%20near%20Nadra%20Office%2C%20Khalid%20Colony%2C%20Gujranwala%2C%20Punjab%2052250!3m2!1d32.1374765!2d74.1928391!5e0!3m2!1sen!2s!4v1726167024505!5m2!1sen!2s */}
              <iframe
                id="gmap_canvas"
                src=" https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d108111.23327453007!2d74.11043783629779!3d32.137449809564124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x391f2a4226d83811%3A0x3c090f3f28a30918!2sG.T.%20Road%2C%20near%20Nadra%20Office%2C%20Khalid%20Colony%2C%20Gujranwala%2C%20Punjab%2052250!3m2!1d32.1374765!2d74.1928391!5e0!3m2!1sen!2s!4v1726167024505!5m2!1sen!2s"
                frameBorder={0}
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
                style={{ width: "100%", height: "100vh", border: 0 }}
                allowFullScreen=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  

    <div className="container">
      <div className="row">
        <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1">
          <div className="contact-block">
            <div className="contact-info">
              <div className="row">
                <div className="col-sm-4">
                  <figure className="text-center">
                    <span className="icon icon-map-marker" />
                    <figcaption>
                      <strong>Where are we?</strong>
                      <span>G.T. Road, near Nadra Office,  <br />Khalid Colony,Gujranwala, Punjab 52250</span>
                    </figcaption>
                  </figure>
                </div>
                <div className="col-sm-4">
                  <figure className="text-center">
                    <span className="icon icon-phone" />
                    <figcaption>
                      <strong>Call us</strong>
                      <span>
                        <strong>T</strong>  +92-55-111-936-936<br />
                        <strong>M</strong> +92-333-0348378
                      </span>
                    </figcaption>
                  </figure>
                </div>
                <div className="col-sm-4">
                  <figure className="text-center">
                    <span className="icon icon-clock" />
                    <figcaption>
                      <strong>Working hours</strong>
                      <span>
                        <strong>Mon</strong> Sun-Thu and Sat: 09:00–17:00  <br />
                        <strong>Sun</strong> Friday: Closed
                      </span>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
            <div className="banner">
              <div className="row">
                <div className="col-md-offset-1 col-md-10 text-center">
                  <h2 className="title">Send an email</h2>
                  <p>
                    Thanks for your interest in Citizen Internation Plastic Industries. We believe in creativity as one of the major forces of progress.
                    Please use this form if you have any questions about our products and we'll get back with you very soon.
                  </p>
                  {showForm ? (<>
                      <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input id="Name" name="Name" type="text"  value={name}  onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Your name" required />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input id="Email" name="Email" type="email"  value={email}  onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Your email" required />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input id="phone" name="phone" type="number" value={phone}  onChange={(e) => setPhone(e.target.value)} className="form-control" placeholder="Phone Number" required />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea id="Comment" name="Comment" value={message}  onChange={(e) => setMessage(e.target.value)} className="form-control" placeholder="Your message" rows={10} defaultValue={""} />
                          </div>
                        </div>
                        <div className="col-md-12 text-center">
                          {/* <input type="submit" className="btn btn-clean" defaultValue="Send message" /> */}
                          <button type='submit' className="btn btn-clean">Send message</button>
                        </div>
                      </div>
                    </form>
                    </>) : (<h2 className='title'>Thanks For your Response</h2>
                    )}
                
                  {/* <div className="contact-form-wrapper">
                    <a className="btn btn-clean open-form" data-text-open="Contact us via form" data-text-close="Close form">Contact us via form</a>
                    <div className="contact-form clearfix">
                     
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div className="contact-info">
              <div className="row">
                <div className="col-xs-6 col-sm-4">
                  <figure>
                    <figcaption>
                      <strong>Sales</strong>
                      <span>
                        <strong>T</strong> +1 125 251 4586 <br />
                        <strong>F</strong> +1 251 333 5555
                      </span>
                    </figcaption>
                  </figure>
                </div>
                <div className="col-xs-6 col-sm-4">
                  <figure>
                    <figcaption>
                      <strong>Services</strong>
                      <span>
                        <strong>T</strong> +1 654 333 8541 <br />
                        <strong>F</strong> +1 125 251 5555
                      </span>
                    </figcaption>
                  </figure>
                </div>
                <div className="col-xs-6 col-sm-4">
                  <figure>
                    <figcaption>
                      <strong>Support</strong>
                      <span>
                        <strong>T</strong> +1 222 852 9632 <br />
                        <strong>F</strong> +1 357 333 5555
                      </span>
                    </figcaption>
                  </figure>
                </div>
                <div className="col-xs-6 col-sm-4">
                  <figure>
                    <figcaption>
                      <strong>Human resources</strong>
                      <span>
                        <strong>T</strong> +1 963 333 8745 <br />
                        <strong>F</strong> +1 159 333 5555
                      </span>
                    </figcaption>
                  </figure>
                </div>
                <div className="col-xs-6 col-sm-4">
                  <figure>
                    <figcaption>
                      <strong>Factory</strong>
                      <span>
                        <strong>T</strong> +1 753 333 1259 <br />
                        <strong>F</strong> +1 247 652 5555
                      </span>
                    </figcaption>
                  </figure>
                </div>
                <div className="col-xs-6 col-sm-4">
                  <figure>
                    <figcaption>
                      <strong>Delivery</strong>
                      <span>
                        <strong>T</strong> +1 134 197 7532 <br />
                        <strong>F</strong> +1 291 468 4563
                      </span>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div> */}
          </div>
        </div>{/*col-sm-8*/}
      </div> {/*/row*/}
    </div>{/*/container*/}
  </section>
</div>
</>
  )
}
