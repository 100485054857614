import React, { useState } from 'react';

const ChatWidget = () => {
    const openWhatsApp = () => {
        window.open('https://wa.me/+923330348378', '_blank'); // Replace with your WhatsApp number
    };

    // Inline styles for the WhatsApp icon
    const iconStyle = {
        position: 'fixed',
        bottom: '20px',
        left: '20px', // Position the icon on the left
        backgroundColor: '#25D366', // WhatsApp green color
        color: 'white',
        borderRadius: '50%',
        padding: '10px',
        fontSize: '40px',
        cursor: 'pointer',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const iconHoverStyle = {
        backgroundColor: '#128C7E', // Darker green on hover
    };

    const [isHovered, setIsHovered] = React.useState(false);

    return (
        <div
            style={{ ...iconStyle, ...(isHovered ? iconHoverStyle : {}) }}
            onClick={openWhatsApp}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <i className="fa fa-whatsapp"></i>
        </div>
    );
};

export default ChatWidget;
