import React, { useState, useEffect } from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Link, useNavigate } from 'react-router-dom'

import ManageCart from '../Controllers/ManageCart';
import { IMG_BASE_URL } from '../config';
import CreateCustomer from '../FetchApi/CreateCustomer';
import { Helmet } from 'react-helmet';



export default function Checkout3() {
  const [customer, setCustomer] = useState([]);
  // const [orderNo, setOrderNo] = useState();
  const { cartItems } = ManageCart();
  const { checkOut } = CreateCustomer();

  const navigate = useNavigate();
  const totalBilling = cartItems.reduce((acc, item) => acc + (item.SalePrice * item.quantity), 0);
  const totalQuantity = cartItems.reduce((acc, item) => acc + (item.quantity), 0);
  const discount = 0; // 159,00 in Rs.
  const shipping = 0;  // 30,00 in Rs.
  const gst = 0;       // 59,00 in Rs.

  const finalAmount = totalBilling - (discount / 100) + (shipping / 100) + (gst / 100);

  const completeOrder = () => {
    checkOut().then(() => {
      nav();
    }).catch((error) => {
      console.error('Error during checkout:', error);
    });
  }
  const nav = () => {
    window.location.href = '/checkout4'; // Redirect to the new URL
  }
  useEffect(() => {
    if (!localStorage.getItem("cc")) {
        navigate('/cartItems');
    }
    const cc = JSON.parse(localStorage.getItem('cc')) || {};
    // const orderNumber = localStorage.getItem('orderNumber');
    setCustomer(cc);
    // console.log(orderNumber);
    // setOrderNo(orderNumber);
}, []);
const back = () => {

  navigate('/cartItems');
}

   
  return (
    <>
      <Helmet>
        <title>Comfirmation | Checkout | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="Comfirmation | Checkout | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        </Helmet>
    <LoadScripts />
    
   <div>
  <section className="main-header" style={{backgroundImage: 'url(assets/images/gallery-2.jpg)'}}>
    <header>
      <div className="container text-center">
        <h2 className="h2 title">Checkout</h2>
        {/* <ol className="breadcrumb breadcrumb-inverted">
        <li><Link to="/"><span className="icon icon-home" /></Link></li>
       
          <li><Link to="/checkout1">Cart items</Link></li>
          <li><Link to="/checkout2">Information</Link></li>
          <li><Link className="active" to="/checkout3">Comfirmation</Link></li>
          <li><Link to="/checkout4">Receipt</Link></li>

        </ol> */}
      </div>
    </header>
  </section>
  {/* ========================  Step wrapper ======================== */}
  <div className="step-wrapper">
    <div className="container">
      <div className="stepper">
        <ul className="row">
          <li className="col-md-3 active">
            <span data-text="Cart items" />
          </li>
          <li className="col-md-3 active">
                  <span data-text="Information" />
                </li>
                <li className="col-md-3 active">
                  <span data-text="Comfirmation" />
                </li>
                <li className="col-md-3">
                  <span data-text="Receipt" />
                </li>
        </ul>
      </div>
    </div>
  </div>
  {/* ========================  Checkout ======================== */}
  <section className="checkout">
    <div className="container">
      <header className="hidden">
        <h3 className="h3 title">Checkout - Step 3</h3>
      </header>
      {/* ========================  Cart navigation ======================== */}
      <div className="clearfix">
        <div className="row">
          <div className="col-xs-6">
            <Link to="/checkout2" className="btn btn-clean-dark"><span className="icon icon-chevron-left" /> Back to Information</Link>
          </div>
          <div className="col-xs-6 text-right">
          <button onClick={completeOrder} className="btn btn-main"><span className="icon icon-cart" /> Checkout</button>
          </div>
        </div>
      </div>
      {/* ========================  Payment ======================== */}
      <div className="cart-wrapper">
        <div className="note-block">
          <div className="row">
    
            <div className="col-md-12">
              <div className="white-block">
                {/* <div className="h4">Order details</div>
                <hr />
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Order no.</strong> <br />
                      <span>{orderNo ? {orderNo} : <p>Loading ...</p>}</span>
                    </div>
                  </div>
             
                </div> */}
                <div className="h4">Shipping info</div>
                <hr />
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Name</strong> <br />
                      <span>{customer.FirstName} {customer.LastName}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Email</strong><br />
                      <span>{customer.EmailAddress}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Phone</strong><br />
                      <span>{customer.PhoneNo}</span>
                    </div>
                  </div>                                      
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Zip</strong><br />
                      <span>{customer.ZipCode}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>City</strong><br />
                      <span>{customer.CityId}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Address</strong><br />
                      <span>{customer.Address}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Company name</strong><br />
                      <span>{customer.CompanyName}</span>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <strong>Company phone</strong><br />
                      <span>+122 333 6665</span>
                    </div>
                  </div> */}
                </div>
              </div> {/*/col-md-6*/}
            </div>
            {/* === right content === */}
            {/* <div className="col-md-6">
              <div className="white-block">
                <div className="h4">Choose payment</div>
                <hr />
                <span className="checkbox">
                  <input type="radio" id="paymentID1" name="paymentOption" defaultChecked="checked" />
                  <label htmlFor="paymentID1">
                    <strong>Cash On Delivery(COD)</strong> <br />
                    <small>Cash on delivery at doorstep where our stores exists (Only Specific Areas Where we have our official outlets
                    Our Outlet Available At Gujranwala, Lahore, Sialkot, Sargodha, Rawalpindi, Bahawalpur, Peshawar, Karachi)</small>
                  </label>
                </span>
                <span className="checkbox">
                  <input type="radio" id="paymentID2" name="paymentOption" />
                  <label htmlFor="paymentID2">
                    <strong>Pickup Payment</strong> <br />
                    <small>Where customers pay when they pick up their orders from our outlets?</small>
                  </label>
                </span>
                <span className="checkbox">
                  <input type="radio" id="paymentID3" name="paymentOption" />
                  <label htmlFor="paymentID3">
                    <strong>Pay via bank transfer</strong> <br />
                    <small>You can make payments directly into our bank account and email or whatapp the bank  receipt to us.  </small>
                  </label>
                </span>
                <hr />
                <p>Please allow three working days for the payment confirmation to reflect in your <a href="#">online account</a>. Once your payment is confirmed, we will generate your e-invoice, which you can view/print from your account or email.</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* ========================  Cart wrapper ======================== */}
      <div className="cart-wrapper">
            {/*cart header */}
            <div className="cart-block cart-block-header clearfix">
              <div>
                <span>Product</span>
              </div>
              <div>
                <span>&nbsp;</span>
              </div>
              <div>
                <span>Quantity</span>
              </div>
              <div className="text-right">
                <span>Price</span>
              </div>
            </div>
            {/*cart items*/}
            <div className="clearfix">
              {cartItems.length > 0 ? (
                cartItems.map((item) => (
                  <div className="cart-block cart-block-item clearfix" key={item.Id}>
                    <div className="image">
                      <Link to={`/product/${item.Id}/${item.Slug}`}> <center><img src={`${IMG_BASE_URL}${encodeURIComponent(item.Thumbnai)}`} alt="pic" style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100px' }} /></center></Link>
                    </div>
                    <div className="title">
                      <div className="h4"><a href="product.html">{item.Name}</a></div>
                      <div>{item.scName}</div>
                    </div>
                    <div className="quantity">
                      <strong>{item.quantity}</strong>
                    </div>
                    <div className="price">
                      <span className="final h3">Rs.  {(parseFloat(item.SalePrice) * item.quantity).toFixed(2)}</span>
                      {/* <span className="discount">Rs. 2.666</span> */}
                    </div>
                  </div>
                ))
              ) : (
                <>

{back && back()} {/* Call the back function if it's defined */}

                  <div className='cart-block cart-block-item clearfix'>
                  <h2 align="center">Empty Cart</h2>
                </div>
                </>
              
              )}

              {/* <div className="cart-block cart-block-item clearfix">
            <div className="image">
              <a href="product.html"><img src="assets/images/product-2.png" alt /></a>
            </div>
            <div className="title">
              <div className="h4"><a href="product.html">Green corner</a></div>
              <div>Green corner</div>
            </div>
            <div className="quantity">
              <strong>1</strong>
            </div>
            <div className="price">
              <span className="final h3">Rs. 1.998</span>
              <span className="discount">Rs. 2.666</span>
            </div>
          </div>
          <div className="cart-block cart-block-item clearfix">
            <div className="image">
              <a href="product.html"><img src="assets/images/product-3.png" alt /></a>
            </div>
            <div className="title">
              <div className="h4"><a href="product.html">Green corner</a></div>
              <div>Green corner</div>
            </div>
            <div className="quantity">
              <strong>1</strong>
            </div>
            <div className="price">
              <span className="final h3">Rs. 1.998</span>
              <span className="discount">Rs. 2.666</span>
            </div>
          </div>
          <div className="cart-block cart-block-item clearfix">
            <div className="image">
              <a href="product.html"><img src="assets/images/product-3.png" alt /></a>
            </div>
            <div className="title">
              <div className="h4"><a href="product.html">Green corner</a></div>
              <div>Green corner</div>
            </div>
            <div className="quantity">
              <strong>1</strong>
            </div>
            <div className="price">
              <span className="final h3">Rs. 1.998</span>
              <span className="discount">Rs. 2.666</span>
            </div>
          </div> */}
            </div>
            {/*cart prices */}
            <div className="clearfix">
              <div className="cart-block cart-block-footer clearfix">
                <div>
                  <strong>Discount</strong>
                </div>
                <div>
                  <span>Rs. {discount / 100}</span>
                </div>
              </div>
              <div className="cart-block cart-block-footer clearfix">
                <div>
                  <strong>Shipping</strong>
                </div>
                <div>
                  <span>Rs. {shipping / 100}</span>
                </div>
              </div>
              <div className="cart-block cart-block-footer clearfix">
                <div>
                  <strong>GST</strong>
                </div>
                <div>
                  <span>Rs. {gst / 100}</span>
                </div>
              </div>
            </div>
            {/*cart final price */}
            <div className="clearfix">
              <div className="cart-block cart-block-footer clearfix">
                <div>
                  <strong>Promo code included!</strong>
                </div>
                <div>
                  <div className="h2 title">Rs. {finalAmount.toFixed(2)}</div>
                </div>
              </div>
            </div>
          </div>
      {/* ========================  Cart navigation ======================== */}
      <div className="clearfix">
        <div className="row">
          <div className="col-xs-6">
            <Link to="/checkout2" className="btn btn-clean-dark"><span className="icon icon-chevron-left" /> Back to Information</Link>
          </div>
          <div className="col-xs-6 text-right">
            <button onClick={completeOrder} className="btn btn-main"><span className="icon icon-cart" /> Checkout</button>
          </div>
        </div>
      </div>
    </div> {/*/container*/}
  </section>
</div>

    
    </>
  )
}
