import './App.css';
import MainContainer from './Components/MainContainer';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
 
  return (
    <>
    <Routes>
      <Route path='/*' element={<MainContainer />} />
    </Routes>
      {/* <MainContainer /> */}
    </>
  );
}

export default App;
