import React, { useEffect, useState } from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Link, useNavigate } from 'react-router-dom'
import ManageCart from '../Controllers/ManageCart';
import { IMG_BASE_URL } from '../config';
import CreateCustomer from '../FetchApi/CreateCustomer';
import { Helmet } from 'react-helmet';


export default function Checkout2() {
  const { addCustomer, customer, createOrder } = CreateCustomer();
  const { cartItems } = ManageCart();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedPayment, setSelectedPayment] = useState(1);
  const navigate = useNavigate();
  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);

  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]+$/;
    return phoneRegex.test(phone);
  };

  const handleCreateAccount = () => {
    const newErrors = {};

    if (!firstName) newErrors.firstName = 'First name is required';
    if (!lastName) newErrors.lastName = 'Last name is required';
    if (!zipCode) newErrors.zipCode = 'Zip code is required';
    if (!city) newErrors.city = 'City is required';
    if (!address) newErrors.address = 'Address is required';
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!validateEmail(email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    if (!phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!validatePhone(phone)) {
      newErrors.phone = 'Please enter a valid phone number';
    }
    if (!isChecked) {
      newErrors.isChecked = 'You must accept the terms and conditions';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // console.log(firstName, lastName, email, phone, city, zipCode, companyName);
      // Proceed with form submission
      
      addCustomer(firstName, lastName, companyName, zipCode, city, email, phone, address, finalAmount, totalQuantity, selectedPayment);
    }
  };
  const back = () => {

    navigate('/cartItems');
  }
  const totalBilling = cartItems.reduce((acc, item) => acc + (item.SalePrice * item.quantity), 0);
  const totalQuantity = cartItems.reduce((acc, item) => acc + (item.quantity), 0);
  const discount = 0; // 159,00 in Rs.
  const shipping = 0;  // 30,00 in Rs.
  const gst = 0;       // 59,00 in Rs.

  const finalAmount = totalBilling - (discount / 100) + (shipping / 100) + (gst / 100);

  return (
    <>
      <Helmet>
        <title>Information | Checkout | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="Information | Checkout | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        </Helmet>
      <LoadScripts />
      <section className="main-header" style={{ backgroundImage: 'url(assets/images/gallery-2.jpg)' }}>
        <header>
          <div className="container text-center">
            <h2 className="h2 title">Checkout</h2>
            {/* <ol className="breadcrumb breadcrumb-inverted">

              <li><Link to="/"><span className="icon icon-home" /></Link></li>
              <li><Link to="/cartItems">Cart items</Link></li>
              <li><Link className="active" to="/checkout2">Information</Link></li>
              <li><Link to="/checkout3">Comfirmation</Link></li>
              <li><Link to="/checkout4">Receipt</Link></li>

            </ol> */}
          </div>
        </header>
      </section>
      {/* ========================  Step wrapper ======================== */}
      <div className="step-wrapper">
        <div className="container">
          <div className="stepper">
            <ul className="row">
              <li className="col-md-3 active">
                <span data-text="Cart items" />
              </li>
              <li className="col-md-3 active">
                  <span data-text="Information" />
                </li>
                <li className="col-md-3">
                  <span data-text="Comfirmation" />
                </li>
                <li className="col-md-3">
                  <span data-text="Receipt" />
                </li>
            </ul>
          </div>
        </div>
      </div>
      {/* ========================  Checkout ======================== */}
      <section className="checkout">
        <div className="container">
          <header className="hidden">
            <h3 className="h3 title">Checkout - Step 2</h3>
          </header>
          {/* ========================  Cart navigation ======================== */}
          <div className="clearfix">
            <div className="row">
              <div className="col-xs-6">
                <Link to="/cartItems" className="btn btn-clean-dark"><span className="icon icon-chevron-left" /> Back to cart</Link>
              </div>
              <div className="col-xs-6 text-right">
              <button onClick={handleCreateAccount} className="btn btn-main"><span className="icon icon-cart" /> Go to payment</button>
              </div>
            </div>
          </div>
          {/* ========================  Delivery ======================== */}
          <div className="cart-wrapper">
            <div className="note-block">
              <div className="row">
                {/* === left content === */}
                <div className="col-md-6">
                  {/* === login-wrapper === */}
                  <div className="login-wrapper">
                    <div className="white-block">
                      {/*signin*/}
                      {/* <div className="login-block login-block-signin">
                        <div className="h4">Sign in <a href="#" onClick={(e) => e.preventDefault()} className="btn btn-main btn-xs btn-register pull-right">create an account</a></div>
                        <hr />
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="User ID" />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input type="password" className="form-control" placeholder="Password" />
                            </div>
                          </div>
                          <div className="col-xs-6">
                            <span className="checkbox">
                              <input type="checkbox" id="checkBoxId3" />
                              <label htmlFor="checkBoxId3">Remember me</label>
                            </span>
                          </div>
                          <div className="col-xs-6 text-right">
                            <a href="#" className="btn btn-main">Login</a>
                          </div>
                        </div>
                      </div>  */}
                      {/*/signin*/}
                      {/*signup*/}
                      <div className="login-block login-block-signup">
                        {/* <div className="h4">
                          Register now
                          <a href="#" onClick={(e) => e.preventDefault()} className="btn btn-main btn-xs btn-login pull-right">
                            Log in
                          </a>
                        </div> */}
                        {/* <hr /> */}
                        <div className="h4">Billing Information</div>
                        <hr />
                        <div className="row">

                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="First name: *"
                              />
                              {errors.firstName && <div className="text-warning">{errors.firstName}</div>}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Last name: *"
                              />
                              {errors.lastName && <div className="text-warning">{errors.lastName}</div>}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                placeholder="Company name:"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder="Address"
                              />
                              {errors.address && <div className="text-warning">{errors.address}</div>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                value={zipCode}
                                onChange={(e) => setZipCode(e.target.value)}
                                placeholder="Zip code: *"
                              />
                              {errors.zipCode && <div className="text-warning">{errors.zipCode}</div>}
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                placeholder="City: *"
                              />
                              {errors.city && <div className="text-warning">{errors.city}</div>}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email: *"
                              />
                              {errors.email && <div className="text-warning">{errors.email}</div>}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="Phone: *"
                              />
                              {errors.phone && <div className="text-warning">{errors.phone}</div>}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <hr />
                            <span className="checkbox">
                              <input
                                type="checkbox"
                                id="checkBoxId1"
                                checked={isChecked}
                                onChange={(e) => setIsChecked(e.target.checked)}
                                required
                              />
                              <label htmlFor="checkBoxId1">
                                I have read and accepted the <a href="#">terms</a>, as well as read and understood our terms of <a href="#">business conditions</a>
                              </label>
                              {errors.isChecked && <div className="text-warning">{errors.isChecked}</div>}
                            </span>
                            <hr />
                          </div>
                          <div className="col-md-12">
                            {/* <button className="btn btn-main btn-block" onClick={handleCreateAccount}>
                              Create Account
                            </button> */}
                          </div>
                        </div>
                      </div> {/*/signup*/}
                    </div>
                  </div> {/*/login-wrapper*/}
                </div> {/*/col-md-6*/}
                {/* === right content === */}
                <div className="col-md-6">
                  <div className="white-block">
                    <div className="h4">Choose payment</div>
                    <hr />
                    <span className="checkbox">
                      <input
                        type="radio"
                        id="paymentID1"
                        name="paymentOption"
                        value= {1}
                        defaultChecked={true}
                        onChange={handlePaymentChange}
                      />
                      <label htmlFor="paymentID1">
                        <strong>Cash On Delivery(COD)</strong> <br />
                        <small>
                          Cash on delivery at doorstep where our stores exist (Only specific areas where we have our official outlets: Gujranwala, Lahore, Sialkot, Sargodha, Rawalpindi, Bahawalpur, Peshawar, Karachi)
                        </small>
                      </label>
                    </span>
                    <span className="checkbox">
                      <input
                        type="radio"
                        id="paymentID2"
                        name="paymentOption"
                        value={2}
                        onChange={handlePaymentChange}
                      />
                      <label htmlFor="paymentID2">
                        <strong>Pickup Payment</strong> <br />
                        <small>Where customers pay when they pick up their orders from our outlets.</small>
                      </label>
                    </span>
                    <span className="checkbox">
                      <input
                        type="radio"
                        id="paymentID3"
                        name="paymentOption"
                        value={3}
                        onChange={handlePaymentChange}
                      />
                      <label htmlFor="paymentID3">
                        <strong>Pay via bank transfer</strong> <br />
                        <small>You can make payments directly into our bank account and email or WhatsApp the bank receipt to us.</small>
                      </label>
                    </span>
                    <hr />
                    <p>Please allow three working days for the payment confirmation to reflect in your <a href="#">online account</a>. Once your payment is confirmed, we will generate your e-invoice, which you can view/print from your account or email.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ========================  Cart wrapper ======================== */}
          <div className="cart-wrapper">
            {/*cart header */}
            <div className="cart-block cart-block-header clearfix">
              <div>
                <span>Product</span>
              </div>
              <div>
                <span>&nbsp;</span>
              </div>
              <div>
                <span>Quantity</span>
              </div>
              <div className="text-right">
                <span>Price</span>
              </div>
            </div>
            {/*cart items*/}
            <div className="clearfix">
              {cartItems.length > 0 ? (
                cartItems.map((item) => (
                  <div className="cart-block cart-block-item clearfix" key={item.Id}>
                    <div className="image">
                      <Link to={`/product/${item.Id}/${item.Slug}`}> <center><img src={`${IMG_BASE_URL}${encodeURIComponent(item.Thumbnai)}`} alt="pic" style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100px' }} /></center></Link>
                    </div>
                    <div className="title">
                      <div className="h4"><a href="product.html">{item.Name}</a></div>
                      <div>{item.scName}</div>
                    </div>
                    <div className="quantity">
                      <strong>{item.quantity}</strong>
                    </div>
                    <div className="price">
                      <span className="final h3">Rs.  {(parseFloat(item.SalePrice) * item.quantity).toFixed(2)}</span>
                      {/* <span className="discount">Rs. 2.666</span> */}
                    </div>
                  </div>
                ))
              ) : (<>
    {back && back()} {/* Call the back function if it's defined */}
    <div className='cart-block cart-block-item clearfix'>
                  <h2 align="center">Empty Cart</h2>
                </div>
                </>
              )}

              {/* <div className="cart-block cart-block-item clearfix">
            <div className="image">
              <a href="product.html"><img src="assets/images/product-2.png" alt /></a>
            </div>
            <div className="title">
              <div className="h4"><a href="product.html">Green corner</a></div>
              <div>Green corner</div>
            </div>
            <div className="quantity">
              <strong>1</strong>
            </div>
            <div className="price">
              <span className="final h3">Rs. 1.998</span>
              <span className="discount">Rs. 2.666</span>
            </div>
          </div>
          <div className="cart-block cart-block-item clearfix">
            <div className="image">
              <a href="product.html"><img src="assets/images/product-3.png" alt /></a>
            </div>
            <div className="title">
              <div className="h4"><a href="product.html">Green corner</a></div>
              <div>Green corner</div>
            </div>
            <div className="quantity">
              <strong>1</strong>
            </div>
            <div className="price">
              <span className="final h3">Rs. 1.998</span>
              <span className="discount">Rs. 2.666</span>
            </div>
          </div>
          <div className="cart-block cart-block-item clearfix">
            <div className="image">
              <a href="product.html"><img src="assets/images/product-3.png" alt /></a>
            </div>
            <div className="title">
              <div className="h4"><a href="product.html">Green corner</a></div>
              <div>Green corner</div>
            </div>
            <div className="quantity">
              <strong>1</strong>
            </div>
            <div className="price">
              <span className="final h3">Rs. 1.998</span>
              <span className="discount">Rs. 2.666</span>
            </div>
          </div> */}
            </div>
            {/*cart prices */}
            <div className="clearfix">
              <div className="cart-block cart-block-footer clearfix">
                <div>
                  <strong>Discount</strong>
                </div>
                <div>
                  <span>Rs. {discount / 100}</span>
                </div>
              </div>
              <div className="cart-block cart-block-footer clearfix">
                <div>
                  <strong>Shipping</strong>
                </div>
                <div>
                  <span>Rs. {shipping / 100}</span>
                </div>
              </div>
              <div className="cart-block cart-block-footer clearfix">
                <div>
                  <strong>GST</strong>
                </div>
                <div>
                  <span>Rs. {gst / 100}</span>
                </div>
              </div>
            </div>
            {/*cart final price */}
            <div className="clearfix">
              <div className="cart-block cart-block-footer clearfix">
                <div>
                  <strong>Promo code included!</strong>
                </div>
                <div>
                  <div className="h2 title">Rs. {finalAmount.toFixed(2)}</div>
                </div>
              </div>
            </div>
          </div>
          {/* ========================  Cart navigation ======================== */}
          <div className="clearfix">
            <div className="row">
              <div className="col-xs-6">
                <Link to="/cartitems" className="btn btn-clean-dark"><span className="icon icon-chevron-left" /> Back to cart</Link>
              </div>
              <div className="col-xs-6 text-right">
                <button onClick={handleCreateAccount} className="btn btn-main"><span className="icon icon-cart" /> Go to payment</button>
                {/* <Link to="/checkout3" className="btn btn-main"><span className="icon icon-cart" /> Go to payment</Link> */}
              </div>
            </div>
          </div>
        </div> {/*/container*/}
      </section>
    </>

  )
}
