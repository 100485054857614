import React, { useEffect, useState } from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Link } from 'react-router-dom'
import Category from '../FetchApi/Category';
import Products from '../FetchApi/Products';
import { IMG_BASE_URL } from '../config';
import { Helmet } from 'react-helmet';


export default function Home() {
  const { loadCategory, categories } = Category();
  const { loadProducts, products } = Products();
  const  [visibleCount, setVisibleCount ] = useState(3); // Initially show 3 categories


  const showMoreCategories = () => {
    setVisibleCount(prevCount => prevCount + 3); // Load 3 more categories on each click
  };

  useEffect(() => {

    loadCategory();
    loadProducts();
  }, []); // Call the loadCategory function when the component mounts

  const hasCategories = Array.isArray(categories.categories);
  const hasProducts = Array.isArray(products.products);
  return (
    <>
       <Helmet>
        <title>Citizen Plastic Furniture | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="Citizen Plastic Furniture | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        </Helmet>
    
      <LoadScripts />
      <section className="header-content">
        <div className="owl-slider">
          {/* === slide item === */}
          <div className="item" style={{ backgroundImage: 'url(assets/images/gallery-1.jpg)' }}>
            <div className="box">
              <div className="container">
                <h2 className="title animated h1" data-animation="fadeInDown">Mesh Back <br />Revolving Chair</h2>
                <div className="animated" data-animation="fadeInUp">
                  Modern &amp; powerfull template. <br /> Clean design &amp; reponsive
                  layout. Google fonts integration
                </div>
                {/* <div className="animated" data-animation="fadeInUp">
                    <a href="https://themeforest.net/item/mobel-furniture-website-template/20382155" target="_blank"  rel="noreferrer" className="btn btn-main"><i className="icon icon-cart" /> Buy this template</a>
                  </div> */}
              </div>
            </div>
          </div>
          {/* === slide item === */}
          <div className="item" style={{ backgroundImage: 'url(assets/images/gallery-2.jpg)' }}>
            <div className="box">
              <div className="container">
                <h2 className="title animated h1" data-animation="fadeInDown">Citizen International</h2>
                <div className="animated" data-animation="fadeInUp">Unlimited Choices. Unbeatable Prices. Free Shipping.</div>
                <div className="animated" data-animation="fadeInUp">Furniture category icon fonts!</div>
                <div className="animated" data-animation="fadeInUp">
                  <a href="category.html" className="btn btn-clean">Get insipred</a>
                </div>
              </div>
            </div>
          </div>
          {/* === slide item === */}
          <div className="item" style={{ backgroundImage: 'url(assets/images/gallery-3.jpg)' }}>
            <div className="box">
              <div className="container">
                <h2 className="title animated h1" data-animation="fadeInDown">
                  Citizen International
                </h2>
                <div className="desc animated" data-animation="fadeInUp">
                  Combine with animate.css. Or just use your own!.
                </div>
                <div className="desc animated" data-animation="fadeInUp">
                  Bunch of typography effects.
                </div>
              </div>
            </div>
          </div>
        </div> {/*/owl-slider*/}
      </section>
    
      {/* ========================  Products widget ======================== */}
    
      <section className="products">
        <div className="container">
          {/* === header title === */}
          <header>
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h2 className="title">Popular products</h2>
                <div className="text">
                  <p>Check out our latest collections</p>
                </div>
              </div>
            </div>
          </header>
          <div className="row">
            {hasProducts && products.products.length > 0 ? (
              products.products.slice(0, 6).map((product, index) => (
                <React.Fragment key={product.Id}> 

 
                <div className="col-md-4 col-xs-6">
                  <article>
                    <div className="info">
                      <span className="add-favorite added">
                        <a href="#" onClick={(e) => e.preventDefault()} data-title="Add to favorites" data-title-added="Added to favorites list"><i className="icon icon-heart" /></a>
                      </span>
                      <span>
                      <Link to={`product/${product.Id}/${product.Slug}`}><i className="icon icon-eye" /></Link>
                      </span>
                    </div>
                    <div className="btn btn-add">
                      <i className="icon icon-cart" />
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="figure-grid">
                      <div className="image">
                        <a href={`product/${product.Id}/${product.Slug}`}>
                          <center><img src={`${IMG_BASE_URL}${encodeURIComponent(product.Thumbnai)}`}  style={{ width : 'auto', height: 'auto', maxWidth: '100%', maxHeight: '270px'}} /></center>
                        </a>
                      </div>
                      <br />
                      <br />
                      <div className="text">
                        <h2 className="title h4"><Link to={`product/${product.Id}/${product.Slug}`}>{product.Name}</Link></h2>
                        <sub>Rs {product.SalePrice}</sub>
                        <sup>Rs {product.SalePrice}</sup>
                        <span className="description clearfix">{product.LongDescription}</span>
                      </div>
                    </div>
                  </article>
                </div>
                
                 </React.Fragment>
              ))
            ) : (
              <p>Loading Products.</p>
              
            )}
          </div> {/*/row*/}
          {/* === button more === */}
          <div className="wrapper-more">
            <Link to="/shop" className="btn btn-main">View store</Link>
          </div>
          {/* ========================  Product info popup - quick view ======================== */}
        </div> {/*/container*/}
      </section>
      {/* ========================  Stretcher widget ======================== */}
      <section className="stretcher-wrapper">
        {/* === stretcher header === */}
        <header className="hidden">
          {/*remove class 'hidden'' to show section header */}
          <div className="container">
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h1 className="h2 title">Popular categories</h1>
                <div className="text">
                  <p>
                    Whether you are changing your home, or moving into a new one, you will find a huge selection of quality living room furniture,
                    bedroom furniture, dining room furniture and the best value at Furniture factory
                  </p>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* === stretcher === */}
        <ul className="stretcher">
          {/* === stretcher item === */}
          <li className="stretcher-item" style={{ backgroundImage: 'url(assets/images/gallery-2.png)' }}>
            {/*logo-item*/}
            <div className="stretcher-logo">
              <div className="text">
                {/* <span className="f-icon f-icon-bedroom" />
                <span className="text-intro">Bedroom</span> */}
              </div>
            </div>
            {/*main text*/}
            <figure>
              <h4>FULL PLASTIC CHAIRS</h4>
              {/* <figcaption>New furnishing ideas</figcaption> */}
            </figure>
            {/*anchor*/}
            <Link to="/shop">Anchor link</Link>
          </li>
          {/* === stretcher item === */}
          <li className="stretcher-item" style={{ backgroundImage: 'url(assets/images/gallery-3.png)' }}>
            {/*logo-item*/}
            <div className="stretcher-logo">
              <div className="text">
                {/* <span className="f-icon f-icon-sofa" />
                <span className="text-intro">Living room</span> */}
              </div>
            </div>
            {/*main text*/}
            <figure>
              <h4>FULL PLASTIC CRYSTAL</h4>
              {/* <figcaption>Discover the design table collection</figcaption> */}
            </figure>
            {/*anchor*/}
            <Link to="/shop">Anchor link</Link>
          </li>
          {/* === stretcher item === */}
          <li className="stretcher-item" style={{ backgroundImage: 'url(assets/images/gallery-4.png)' }}>
            {/*logo-item*/}
            <div className="stretcher-logo">
              <div className="text">
                {/* <span className="f-icon f-icon-office" />
                <span className="text-intro">Office</span> */}
              </div>
            </div>
            {/*main text*/}
            <figure>
              <h4>PLASTIC CHAIRS STEEL LEGS CRYSTAL</h4>
              {/* <figcaption>Wardrobes vs Walk-In Closets</figcaption> */}
            </figure>
            {/*anchor*/}
            <Link to="/shop">Anchor link</Link>
          </li>
          {/* === stretcher item === */}
          <li className="stretcher-item" style={{ backgroundImage: 'url(assets/images/gallery-1.png)' }}>
            {/*logo-item*/}
            <div className="stretcher-logo">
              <div className="text">
                {/* <span className="f-icon f-icon-bathroom" />
                <span className="text-intro">Bathroom</span> */}
              </div>
            </div>
            {/*main text*/}
            <figure>
              <h4>REVOLVING CHAIR</h4>
              {/* <figcaption>Creating Your Very Own Bathroom</figcaption> */}
            </figure>
            {/*anchor*/}
            <Link to="/shop">Anchor link</Link>
          </li>
          {/* === stretcher item more=== */}
          <li className="stretcher-item more">
            <div className="more-icon">
              <span data-title-show="Show more" data-title-hide="+" />
            </div>
            <Link to="/shop">Anchor link</Link>
          </li>
        </ul>
      </section>
      {/* ========================  Blog Block ======================== */}
      {/* ========================  Blog ======================== */}
      <section className="blog">
        <div className="container">
          {/* === blog header === */}
          <header>
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h1 className="h2 title">Categories</h1>
                <div className="text">
                  <p>Explore our Categories</p>
                </div>
              </div>
            </div>
          </header>
          <div className="row">
            {/* === blog items generated from categories === */}
            {hasCategories && categories.categories.length > 0 ? (
              categories.categories.slice(0, visibleCount).map((category, index) => (
                <div className="col-sm-4" key={index}>
                  <article>
                    <Link to="/shop">
                      <div className="image" style={{ backgroundImage: `url(${IMG_BASE_URL}${encodeURIComponent(category.Picture)})` }}>
                        <img src={`${IMG_BASE_URL}${encodeURIComponent(category.Picture)}`} alt={category.Name} />
                      </div>
                      <div className="entry entry-table">
                        <div className="title">
                          <h2 className="h5">{category.Name}</h2>
                          <h2 className="h6">{category.Description}</h2>
                        </div>
                      </div>
                      <div className="show-more">
                        {/* <Link to="/shop"> */}
                        <span className="btn btn-main btn-block">Read more</span>
                        {/* </Link> */}
                      </div>
                    </Link>
                  </article>
                </div>
              ))
            ) : (
              <p>No categories available.</p>
            )}
          </div> {/*/row*/}
          {/* === button more === */}
          {hasCategories && visibleCount < categories.categories.length && (
            <div className="wrapper-more">
              <button onClick={showMoreCategories} className="btn btn-main">Show more</button>
            </div>
          )}
        </div> {/*/container*/}
      </section>
      {/* ========================  Banner ======================== */}
      <section className="banner" style={{ backgroundImage: 'url(assets/images/gallery-4.jpg)' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-offset-2 col-md-8 text-center">
              <h2 className="title">Our story</h2>
              <p>
                We believe in creativity as one of the major forces of progress. With this idea, we traveled throughout Italy to find exceptional
                artisans and bring their unique handcrafted objects to connoisseurs everywhere.
              </p>
              <p><a href="about.html" className="btn btn-clean">Read full story</a></p>
            </div>
          </div>
        </div>
      </section>
      {/* ========================  Blog ======================== */}
      <section className="blog">
        <div className="container">
          {/* === blog header === */}
          <header>
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h1 className="h2 title">Blog</h1>
                <div className="text">
                  <p>Latest news from the blog</p>
                </div>
              </div>
            </div>
          </header>
          <div className="row">
            {/* === blog item === */}
            <div className="col-sm-4">
              <article>
                <a href="article.html">
                  <div className="image" style={{ backgroundImage: 'url(assets/images/blog-1.jpg)' }}>
                    <img src="assets/images/blog-1.jpg"  />
                  </div>
                  <div className="entry entry-table">
                    <div className="date-wrapper">
                      <div className="date">
                        <span>MAR</span>
                        <strong>08</strong>
                        <span>2017</span>
                      </div>
                    </div>
                    <div className="title">
                      <h2 className="h5">The 3 Tricks that Quickly Became Rules</h2>
                    </div>
                  </div>
                  <div className="show-more">
                    <span className="btn btn-main btn-block">Read more</span>
                  </div>
                </a>
              </article>
            </div>
            {/* === blog item === */}
            <div className="col-sm-4">
              <article>
                <a href="article.html">
                  <div className="image" style={{ backgroundImage: 'url(assets/images/blog-2.jpg)' }}>
                    <img src="assets/images/blog-1.jpg"  />
                  </div>
                  <div className="entry entry-table">
                    <div className="date-wrapper">
                      <div className="date">
                        <span>MAR</span>
                        <strong>03</strong>
                        <span>2017</span>
                      </div>
                    </div>
                    <div className="title">
                      <h2 className="h5">Decorating When You're Starting Out or Starting Over</h2>
                    </div>
                  </div>
                  <div className="show-more">
                    <span className="btn btn-main btn-block">Read more</span>
                  </div>
                </a>
              </article>
            </div>
            {/* === blog item === */}
            <div className="col-sm-4">
              <article>
                <a href="article.html">
                  <div className="image" style={{ backgroundImage: 'url(assets/images/blog-8.jpg)' }}>
                    <img src="assets/images/blog-8.jpg"  />
                  </div>
                  <div className="entry entry-table">
                    <div className="date-wrapper">
                      <div className="date">
                        <span>MAR</span>
                        <strong>01</strong>
                        <span>2017</span>
                      </div>
                    </div>
                    <div className="title">
                      <h2 className="h5">What does your favorite dining chair say about you?</h2>
                    </div>
                  </div>
                  <div className="show-more">
                    <span className="btn btn-main btn-block">Read more</span>
                  </div>
                </a>
              </article>
            </div>
          </div> {/*/row*/}
          {/* === button more === */}
          <div className="wrapper-more">
            <Link to="blog-grid.html" className="btn btn-main">View all posts</Link>
          </div>
        </div> {/*/container*/}
      </section>
      {/* ========================  Instagram ======================== */}
      <section className="instagram">
        {/* === instagram header === */}
        <header>
          <div className="container">
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h2 className="h2 title">Follow us 
                  {/* <i className="fa fa-instagram fa-2x" /> Instagram  */}
                  </h2>
                <div className="text">
                  <p>@CitizenPlastic</p>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* === instagram gallery === */}
        {/* <div className="gallery clearfix">
          <a className="item" href="/">
            <img src="assets/images/square-1.jpg" ="Alternate Text" />
          </a>
          <a className="item" href="/">
            <img src="assets/images/square-2.jpg" ="Alternate Text" />
          </a>
          <a className="item" href="/">
            <img src="assets/images/square-3.jpg" ="Alternate Text" />
          </a>
          <a className="item" href="/">
            <img src="assets/images/square-4.jpg" ="Alternate Text" />
          </a>
          <a className="item" href="/">
            <img src="assets/images/square-5.jpg" ="Alternate Text" />
          </a>
          <a className="item" href="/">
            <img src="assets/images/square-6.jpg" ="Alternate Text" />
          </a>
        </div> /gallery */}
      </section>
    </>
  )
}
