import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './Header'
import Home from '../Pages/Home';
import Footer from './Footer';
import Shop from '../Pages/Shop';
import About from '../Pages/About';
import Contact from '../Pages/Contact';
import Login from '../Pages/Login';
import Checkout1 from '../Pages/Checkout1';
import Checkout2 from '../Pages/Checkout2';
import Checkout3 from '../Pages/Checkout3';
import Checkout4 from '../Pages/Checkout4';
import NotFound from '../Pages/NotFound';
import ProductPage from '../Pages/ProductPage';
import ChatWidget from './ChatWidget';
import TrackOrder from '../Pages/TrackOrder';

export default function MainContainer() {
  useEffect(() => {
    if (!localStorage.getItem("cart")) {
        localStorage.setItem("cart", JSON.stringify([]));
    }
}, []);
  
  return (
    <>
    {/* <div className="page-loader"></div> */}
      <div className="wrapper">
      <Header />
      <ChatWidget />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/shop' element={<Shop />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/login' element={<Login />} />
        <Route path='/cartItems' element={<Checkout1 />} />
        <Route path='/checkout2' element={<Checkout2 />} />
        <Route path='/checkout3' element={<Checkout3 />} />
        <Route path='/checkout4' element={<Checkout4 />} />
        <Route path='/trackorder' element={<TrackOrder />}/>
        <Route path='/product/:id/:slug' element={<ProductPage />} />
        <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
      </Routes>
        {/* ----- */}
       {/* <Home /> */}
      <Footer />
      </div>
    </>
  )
}


