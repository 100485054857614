import React from 'react'
import StoreApi from '../Controllers/StoreApi';
import { useState, useEffect } from 'react';

export default function Products() {
    const { http} = StoreApi();
    const [products, setProducts] = useState([]);
    const [productDetail, setProductDetail] = useState(null);

    const loadProducts = () => {
        http.get("/fetchAllProducts", { 
         })
          .then(response => {
            if (response.status === 200) {
              // console.log('API response:', response.data);
              setProducts(response.data);
            } else {
              console.error("Fetching productDetail failed:", response.data.message);
            }
          })
          .catch(error => {
            console.error("Error during fetching productDetail:", error.message);
            // localStorage.clear();
            // navigate('/login');
          })
          .finally(() => {
            // setLoading(false);
          });

 
      }

      const loadProductDetails = (id) => {
        // console.log("Loading product details for ID:", id);
        
        http.post("/fetchProductDetails", { id: id })
          .then(response => {
            // console.log("Raw API response:", response);
            if (response.status === 200) {
              // console.log('Parsed API response:', response.data);
              setProductDetail(response.data); // Assuming response.data is the product details object
            } else {
              console.error("Fetching product details failed:", response.data.message);
            }
          })
          .catch(error => {
            console.error("Error during fetching product details:", error.message);
          });
      };
      

      return { loadProducts, products, loadProductDetails, productDetail };
}
