import React from 'react'
import StoreApi from '../Controllers/StoreApi';
import { useState, useEffect } from 'react';

export default function Category() {
    const { http} = StoreApi();
    const [categories, setCategories] = useState([]);

    const loadCategory = () => {
        http.get("/fetchCategory", { 
         })
          .then(response => {
            if (response.status === 200) {
              // console.log('API response:', response.data);
              setCategories(response.data);
            } else {
              console.error("Fetching categories failed:", response.data.message);
            }
          })
          .catch(error => {
            console.error("Error during fetching categories:", error.message);
            // localStorage.clear();
            // navigate('/login');
          })
          .finally(() => {
            // setLoading(false);
          });

 
      }

      return { loadCategory, categories };
}
