import React, {useState, useEffect} from 'react'

export default function ManageCart() {
    const [cartItems, setCartItems] = useState([]);
    const [quantity, setQuantity] = useState(1);
    
    useEffect(() => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        setCartItems(cart);
      }, []);

    const handleOnChangeValue = (event, product) => {
        const newQuantity = Number(event.target.value);
        setQuantity(newQuantity);
    
        // Get the existing cart from localStorage
        const cart = JSON.parse(localStorage.getItem("cart")) || [];
    
        // Check if the product is already in the cart
        const existingProductIndex = cart.findIndex(item => item.Id === product.Id);
        
        if (existingProductIndex !== -1) {
          // Update the product quantity if it is already in the cart
          cart[existingProductIndex].quantity = newQuantity;
          alert('Product quantity updated in cart');
          
          // Save the updated cart back to localStorage
          localStorage.setItem("cart", JSON.stringify(cart));
          setCartItems(cart);
        }
      };
    
    const addToCart = (product, color) => {
        // Get the existing cart from localStorage
        const cart = JSON.parse(localStorage.getItem("cart")) || [];
        
        // Check if the product is already in the cart
        const existingProductIndex = cart.findIndex(item => item.Id === product.Id);
    
        if (existingProductIndex !== -1) {
          // If the product is already in the cart, notify the user
          alert('Product is already in the cart. Quantity has been updated.');
            
        } else {
          // If the product is new to the cart, add it with the specified quantity
          cart.push({ ...product, quantity, color });
          alert('Product added to cart');
          
          
          // Save the updated cart back to localStorage
          localStorage.setItem("cart", JSON.stringify(cart));
          setCartItems(cart);
        }
      };
    const handleDeleteItem = (productId) => {
        const updatedCartItems = cartItems.filter(item => item.Id !== productId);
        setCartItems(updatedCartItems);
        localStorage.setItem('cart', JSON.stringify(updatedCartItems));
      };
   

  
      
  return{ handleOnChangeValue, addToCart, cartItems, handleDeleteItem};
}
