import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Header() {

  return (
   <>
     <nav className="navbar-fixed">
          <div className="container">
            {/* ==========  Top navigation ========== */}
            <div className="navigation navigation-top clearfix">
              <ul>
                {/*add active class for current page*/}
                <li><a href="https://www.facebook.com/CitizenPlastic/" target='_blank'><i className="fa fa-facebook" /></a></li>
                <li><a href="https://www.instagram.com/citizenplastic/" target='_blank'><i className="fa fa-instagram" /></a></li>
                <li><a href="https://twitter.com/CitizenPlastic" target='_blank'><i className="fa fa-twitter" /></a></li>
                <li><a href="https://www.linkedin.com/in/citizen-plastic-62707b152/" target='_blank'><i className="fa fa-linkedin" /></a></li>
                <li><a href="https://www.youtube.com/@citizenplasticfurniture" target='_blank'><i className="fa fa-youtube" /></a></li>
                {/*Currency selector*/}
                {/* <li className="nav-settings">
                  <a  href="#" onClick={(e) => e.preventDefault()} className="nav-settings-value"> USD $</a>
                  <ul className="nav-settings-list">
                    <li>...</li>
                  </ul>
                </li> */}
                {/*Language selector*/}
                {/* <li className="nav-settings">
                  <a  href="#" onClick={(e) => e.preventDefault()} className="nav-settings-value"> ENG</a>
                  <ul className="nav-settings-list">
                    <li>...</li>
                  </ul>
                </li> */}
                <li><Link to='/login' className="open-login"><i className="icon icon-user" /></Link></li>
                {/* <li><a  href="#" onClick={(e) => e.preventDefault()} className="open-search"><i className="icon icon-magnifier" /></a></li> */}
                <li><Link  to="/cartitems" className="open-cart"><i className="icon icon-cart" />  </Link></li>
              </ul>
            </div> {/*/navigation-top*/}
            {/* ==========  Main navigation ========== */}
            <div className="navigation navigation-main">
              {/* Setup your logo here*/}
              <Link to="/" className="logo"><img src="/assets/images/logo1.png" alt="img" /></Link>
              {/* Mobile toggle menu */}
              <a href="#" className="open-menu"><i className="icon icon-menu" /></a>
              {/* Convertible menu (mobile/desktop)*/}
              <div className="floating-menu">
                {/* Mobile toggle menu trigger*/}
                <div className="close-menu-wrapper">
                  <span className="close-menu"><i className="icon icon-cross" /></span>
                </div>
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/shop">Shop</Link></li>
                  <li><Link to="/about">About</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                  {/* Simple menu link*/}
                  {/* <li><a href="shortcodes.html">Shortcodes</a></li> */}
                </ul>
              </div> {/*/floating-menu*/}
            </div> {/*/navigation-main*/}
            {/* ==========  Search wrapper ========== */}
            <div className="search-wrapper">
              {/* Search form */}
              <input className="form-control" placeholder="Search..." />
              <button className="btn btn-main btn-search">Go!</button>
              {/* Search results - live search */}
              <div className="search-results">
                <div className="search-result-items">
                  <div className="title h4">Products <a href="/" className="btn btn-clean-dark btn-xs">View all</a></div>
                  <ul>
                    <li><a href="/"><span className="id">ProdID</span> <span className="name">Product name</span> <span className="category">Product category</span></a></li>
                    <li><a href="/"><span className="id">...</span> <span className="name">...</span> <span className="category">...</span></a></li>
                  </ul>
                </div> {/*/search-result-items*/}
                <div className="search-result-items">
                  <div className="title h4">Blog <a href="/" className="btn btn-clean-dark btn-xs">View all</a></div>
                  <ul>
                    <li><a href="/"><span className="id">Date</span> <span className="name">Article name </span> <span className="category">Category</span></a></li>
                    <li><a href="/"><span className="id">...</span> <span className="name">...</span> <span className="category">...</span></a></li>
                  </ul>
                </div> {/*/search-result-items*/}
              </div> {/*/search-results*/}
            </div>
            {/* ==========  Login wrapper ========== */}
            <div className="login-wrapper">
              <form>
                <div className="h4">Sign in</div>
                <div className="form-group">
                  <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Email" />
                </div>
                <div className="form-group">
                  <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                </div>
                <div className="form-group">
                  <a href="#forgotpassword" className="open-popup">Forgot password?</a>
                  <a href="#createaccount" className="open-popup">Don't have an account?</a>
                </div>
                <button type="submit" className="btn btn-block btn-main">Submit</button>
              </form>
            </div>
            {/* ==========  Cart wrapper ========== */}
            <div className="cart-wrapper">
              <div className="checkout">
                <div className="clearfix">
                  {/*cart item*/}
                  <div className="row">
                    <div className="cart-block cart-block-item clearfix">
                      <div className="image">
                        <a href="product.html"><img src="/assets/images/product-1.png" alt="img" /></a>
                      </div>
                      <div className="title">
                        <div><a href="product.html">Green corner</a></div>
                        <small>Green corner</small>
                      </div>
                      <div className="quantity">
                        <input type="number" defaultValue={2} className="form-control form-quantity" />
                      </div>
                      <div className="price">
                        <span className="final">$ 1.998</span>
                        <span className="discount">$ 2.666</span>
                      </div>
                      {/*delete-this-item*/}
                      <span className="icon icon-cross icon-delete" />
                    </div>
                    {/*cart item*/}
                    <div className="cart-block cart-block-item clearfix">
                      ...
                    </div>
                    {/*cart item*/}
                  </div>
                  <hr />
                  {/*cart prices */}
                  <div className="clearfix">
                    <div className="cart-block cart-block-footer clearfix">
                      <div>
                        <strong>Discount 15%</strong>
                      </div>
                      <div>
                        <span>$ 159,00</span>
                      </div>
                    </div>
                    <div className="cart-block cart-block-footer clearfix">
                      <div>
                        <strong>Shipping</strong>
                      </div>
                      <div>
                        <span>$ 30,00</span>
                      </div>
                    </div>
                    <div className="cart-block cart-block-footer clearfix">
                      <div>
                        <strong>VAT</strong>
                      </div>
                      <div>
                        <span>$ 59,00</span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/*cart final price */}
                  <div className="clearfix">
                    <div className="cart-block cart-block-footer clearfix">
                      <div>
                        <strong>Total</strong>
                      </div>
                      <div>
                        <div className="h4 title">$ 1259,00</div>
                      </div>
                    </div>
                  </div>
                  {/*cart navigation */}
                  <div className="cart-block-buttons clearfix">
                    <div className="row">
                      <div className="col-xs-6">
                        <a href="products-grid.html" className="btn btn-clean-dark">Continue shopping</a>
                      </div>
                      <div className="col-xs-6 text-right">
                        <a href="checkout-1.html" className="btn btn-main"><span className="icon icon-cart" /> Checkout</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> {/*/checkout*/}
            </div> {/*/cart-wrapper*/}
          </div> {/*/container*/}
        </nav>
   </>
  )
}
