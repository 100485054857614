import React, { useEffect, useState } from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Link, useParams } from 'react-router-dom'
import Products from '../FetchApi/Products';
import ManageCart from '../Controllers/ManageCart';
import { IMG_BASE_URL } from '../config';
import { Helmet } from 'react-helmet';
import { setAppElement } from 'react-modal';
export default function ProductPage() {
  const params = useParams();
  const id = params.id;
  const [name, setName] = useState();
  const [thumbNail, setThumbNail] = useState();
  const [description, setDescription] = useState();
  const [stockQty, setStockQty] = useState();
  const [inStock, setInStock] = useState(null);
  const [salePrice, setSalePrice] = useState(null);
  const [sku, setSku] = useState(null);
  const [isOnline, setIsOnline] = useState(true);
  const [seoTitle, setSeoTitle] = useState();
  const [seoDescription, setSeoDescription] = useState();
  const [brandName, setBrandName] = useState();
  // console.log(params.id);
  const { loadProductDetails, productDetail } = Products();
  const { handleOnChangeValue, addToCart } = ManageCart();

  const renderedImages = [];


  if (productDetail) {
    
    for (let i = 0; i < productDetail.length; i++) {
      renderedImages.push(
        // <a href="assets/images/product-2.png"><img src="/assets/images/product-2.png" alt height={500} /></a>
        <a href={`${IMG_BASE_URL}${encodeURIComponent(productDetail[i].attimage)}`} key={i}>
          <center>
          <img
            src={`${IMG_BASE_URL}${encodeURIComponent(productDetail[i].attimage)}`}
            alt={`Product ${i + 2}`}
            style={{ width : 'auto', height: 'auto', maxWidth: '100%', maxHeight: '500px'}}
            height={500}
          />
                    </center>

        </a>
      );
    }
  }

 
  const [color, setColor] = useState(null);  
  const handleClick = (attributeValue) => {
    // Your logic here
    alert(attributeValue);
    setColor(attributeValue);
  };
  useEffect(() => {
    loadProductDetails(id);
  }, [id]);

  useEffect(() => {
    if (productDetail) {
      // console.log("Product details is", productDetail[0]);
      setName(productDetail[0].Name);
      setThumbNail(productDetail[0].Thumbnai);
      setDescription(productDetail[0].LongDescription);
      setStockQty(productDetail[0].StockQty);
      setSalePrice(productDetail[0].SalePrice);
      setSku(productDetail[0].SKU);
      setBrandName(productDetail[0].bname);
      setSeoTitle(productDetail[0].SEOTitle);
      setSeoDescription(productDetail[0].SeoDescription);
      if (productDetail[0].StockQty > 0) {
        setInStock(true);
      } else {
        setInStock(false);
      }
    } else {
      console.log("Product details is still undefined or loading");
    }
  }, [productDetail]);
  return (
    <>
     <LoadScripts />
      <Helmet>
        <title>{seoTitle ? `${seoTitle} | Shop` : 'Loading...'}</title>
        <meta name="description" content={seoDescription ? seoDescription : 'Loading...'} />
        <meta property="og:title" content={seoTitle ? seoTitle : 'Loading...'} />
        <meta property="og:description" content={seoDescription ? seoDescription : 'Loading...'} />
      </Helmet>
      <div>
        {/* ========================  Main header ======================== */}
        <section className="main-header" style={{ backgroundImage: 'url(/assets/images/breadcrumb.jpg)' }}>
          <header>
            <div className="container">
              <h1 className="h2 title">{name ? <>{name}</> : <>Loading...</>}</h1>
              <ol className="breadcrumb breadcrumb-inverted">
                <li><Link to="/"><span className="icon icon-home" /></Link></li>
                <li><Link to="/shop">Shop</Link></li>
                <li>{name ? <>{name}</> : <>Loading...</>}</li>
              </ol>
            </div>
          </header>
        </section>
        {/* ========================  Product ======================== */}
        <section className="product">
          <div className="main">
            <div className="container">
              <div className="row product-flex">
                {/* product flex is used only for mobile order */}
                {/* on mobile 'product-flex-info' goes bellow gallery 'product-flex-gallery' */}
                <div className="col-md-4 col-sm-12 product-flex-info">
                  <div className="clearfix">
                    {/* === product-title === */}
                    <h1 className="title" data-title="CITIZEN">{name ? <>{name}</> : <>Loading...</>} <small>Article No :{sku ? <>{sku}</> : <>Loading...</>}</small></h1>
                    <div className="clearfix">
                      {/* === price wrapper === */}
                      <div className="price">
                        <span className="h3">
                          Rs {salePrice ? <>{salePrice}</> : <>Loading...</>}
                          {/* <small>$ 2999,00</small> */}
                        </span>
                      </div>
                      <hr />
                      {/* === info-box === */}
                      <div className="info-box">
                        <span><strong>Maifacturer</strong></span>
                        <span>{brandName ? <>{brandName}</> : <>Loading...</>}</span>
                      </div>
                      {/* === info-box === */}
                      {/* <div className="info-box">
                  <span><strong>Materials</strong></span>
                  <span>Wood, Leather, Acrylic</span>
                </div> */}
                      {/* === info-box === */}
                      <div className="info-box">
                        <span><strong>Availability</strong></span>
                        {inStock != null ? <>
                          {inStock == true ?
                            <span><i className="fa fa-check-square-o" /> In stock</span>
                            :
                            <span><i className="fa fa-truck" /> Out of stock</span>}
                        </> : <>Loading...</>}

                      </div>
          
                      <hr />
                      {/* === info-box === */}
                      <div className="info-box">
                        <span><strong>Available Colors</strong></span>
                        <div className="product-colors clearfix">
                          {productDetail ? (<>
                            {productDetail.map((productDetail, index) => (
                              <React.Fragment key={index}> 

                                {/* <span className={`color-btn color-btn-${productDetail.AttributeValue.toLowerCase()}`} /> */}
                                {productDetail.AttributeValue && <>
                                <span
                                  className={`color-btn color-btn-${productDetail.AttributeValue.toLowerCase()}`}
                                  onClick={() => handleClick(productDetail.AttributeValue)}
                                />
                                </>
                                }
                              </React.Fragment>

                            ))}
                          </>) : <><p>Loading</p></>}
                          {/* <span className="color-btn color-btn-blue checked" />
                    <span className="color-btn color-btn-green" />
                    <span className="color-btn color-btn-gray" />
                    <span className="color-btn color-btn-biege" /> */}
                        </div>
                      </div>
                      <hr />
                      <div className="info-box">
                        {isOnline ? <>
                          {/* <Link to='/checkout1'> */}
                          <div className="row">
                            <div className='col-md-4 col-sm-12 product-flex-info'>
                              <Link to={color ? '/cartItems' : '#'}><button type="button" className="btn btn-success" onClick={() => {
                              if (!color) {
                                alert('Please select a color before adding to the cart.');
                                return; // Prevents addToCart from being called
                              }
                              addToCart(productDetail[0], color);
                            }}>Add To Cart</button></Link>
                            </div>
                            <div className="col-md-4 col-sm-12 product-flex-info">
                              <div className="quantity"><input type="number" className="form-control form-quantity" defaultValue={1} onChange={(event) => handleOnChangeValue(event, productDetail[0])} /></div>

                            </div>
                          </div>
                          {/* </Link> */}
                        </> : <button type="button" className="btn btn-primary">Get a Qoute</button>
                        }
                      </div>
                      {/* === info-box === */}
                      {/* <div className="info-box">
                  <span><strong>Choose size</strong></span>
                  <div className="product-colors clearfix">
                    <span className="color-btn color-btn-biege">
                      <span className="product-size" data-text>S</span>
                    </span>
                    <span className="color-btn color-btn-biege checked">M</span>
                    <span className="color-btn color-btn-biege">XL</span>
                    <span className="color-btn color-btn-biege">XXL</span>
                  </div>
                </div> */}
                    </div> {/*/clearfix*/}
                  </div> {/*/product-info-wrapper*/}
                </div> {/*/col-md-4*/}
                <div className="col-md-8 col-sm-12 product-flex-gallery">
  
            <div className="owl-product-gallery open-popup-gallery">
              {/* <a href="assets/images/product-1.png"> */}
              <center><img src={`${IMG_BASE_URL}${encodeURIComponent(thumbNail)}`} style={{ width : 'auto', height: 'auto', maxWidth: '100%', maxHeight: '500px'}} /></center>
              {/* </a> */}
               {productDetail ? renderedImages : <>Loading...</>}
           
            </div>
          </div>
              

              </div>
            </div>
          </div>
          {/* === product-info === */}
          <div className="info">
            <div className="container">
              <div className="row">
                {/* === product-designer === */}
        
                {/*/col-md-4*/}
                {/* === nav-tabs === */}
                <div className="col-md-12">
                  <ul className="nav nav-tabs" role="tablist">
          
                    <li role="presentation">
                      <a href="#design" aria-controls="design" role="tab" data-toggle="tab">
                        <i className="icon icon-sort-alpha-asc" />
                        <span>Specification</span>
                      </a>
                    </li>
               
                  </ul>
                  {/* === tab-panes === */}
                  <div className="tab-content">
                  
                    {/* ============ tab #2 ============ */}
                    <div role="tabpanel" className="tab-pane active" id="design">
                      <div className="content">
                        <div className="row">
                    
                          <div className="col-md-12">
                            <h3>Product Specification</h3>
                            <p>
                              {description ? <>{description}</> : <>Loading...</>}
                            </p>
                          </div>
                        </div> {/*/row*/}
                      </div> {/*/content*/}
                    </div> {/*/tab-pane*/}
                    {/* ============ tab #3 ============ */}

                  </div> {/*/tab-content*/}
                </div>
              </div> {/*/row*/}
            </div> {/*/container*/}
          </div> {/*/info*/}
        </section>
        {/* ========================  Product info popup - quick view ======================== */}
        <div className="popup-main mfp-hide" id="productid1">
          {/* === product popup === */}
          <div className="product">
            {/* === popup-title === */}
            <div className="popup-title">
              <div className="h1 title">Laura <small>product category</small></div>
            </div>
            {/* === product gallery === */}
            <div className="owl-product-gallery">
              <img src="/assets/images/product-1.png" width={640} />
              <img src="/assets/images/product-2.png" width={640} />
              <img src="/assets/images/product-3.png" width={640} />
              <img src="/assets/images/product-4.png" width={640} />
            </div>
            {/* === product-popup-info === */}
            <div className="popup-content">
              <div className="product-info-wrapper">
                <div className="row">
                  {/* === left-column === */}
                  <div className="col-sm-6">
                    <div className="info-box">
                      <strong>Maifacturer</strong>
                      <span>Brand name</span>
                    </div>
                    <div className="info-box">
                      <strong>Materials</strong>
                      <span>Wood, Leather, Acrylic</span>
                    </div>
                    <div className="info-box">
                      <strong>Availability</strong>
                      <span><i className="fa fa-check-square-o" /> in stock</span>
                    </div>
                  </div>
                  {/* === right-column === */}
                  <div className="col-sm-6">
                    <div className="info-box">
                      <strong>Available Colors</strong>
                      <div className="product-colors clearfix">
                        <span className="color-btn color-btn-red" />
                        <span className="color-btn color-btn-blue checked" />
                        <span className="color-btn color-btn-green" />
                        <span className="color-btn color-btn-gray" />
                        <span className="color-btn color-btn-biege" />
                      </div>
                    </div>
                    <div className="info-box">
                      <strong>Choose size</strong>
                      <div className="product-colors clearfix">
                        <span className="color-btn color-btn-biege">S</span>
                        <span className="color-btn color-btn-biege checked">M</span>
                        <span className="color-btn color-btn-biege">XL</span>
                        <span className="color-btn color-btn-biege">XXL</span>
                      </div>
                    </div>
                  </div>
                </div>{/*/row*/}
              </div> {/*/product-info-wrapper*/}
            </div>{/*/popup-content*/}
            {/* === product-popup-footer === */}
            <div className="popup-table">
              <div className="popup-cell">
                <div className="price">
                  <span className="h3">$ 1999,00 <small>$ 2999,00</small></span>
                </div>
              </div>
              <div className="popup-cell">
                <div className="popup-buttons">
                  <a href="product.html"><span className="icon icon-eye" /> <span className="hidden-xs">View more</span></a>
                  <a href="#" onClick={(e) => e.preventDefault()}><span className="icon icon-cart" /> <span className="hidden-xs">Buy</span></a>
                </div>
              </div>
            </div>
          </div> {/*/product*/}
        </div> {/*popup-main*/}
      </div>

    </>
  )
}
