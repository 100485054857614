import React from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Helmet } from 'react-helmet';


export default function () {
  return (
    <>
    <Helmet>
      <title>Track Order | Citizen Plastic</title>
      <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      <meta property="og:title" content="About Us | Citizen Plastic" />
      <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      </Helmet>
  <LoadScripts />
  <div>
<section className="main-header main-header-blog" style={{backgroundImage: 'url(assets/images/breadcrumb.jpg)'}}>
  <header>
    <div className="container text-center">
      <h2 className="h2 title">Track Order</h2>
      <p className="subtitle">Track your Order</p>
    </div>
  </header>
</section>
<section className="login-wrapper login-wrapper-page">
    <div className="container">
    <header>
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h2 className="title">Track Your Order by Entrying the Order Now</h2>
              </div>
            </div>
          </header>
          <div className="row">
            <div className="col-md-offset-2 col-md-8">
              <div className="form-group">
                <label>Order ID</label>
                <input type="text" className="form-control" placeholder="Enter your order ID" />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-main">Track Order</button>
              </div>
            </div>
          </div>
    </div>
  </section>
</div>
</>
  )
}
